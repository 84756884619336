var render = function () {
  var _vm$userData, _vm$userData2, _vm$userData2$user, _vm$userData2$user$da, _vm$userData3, _vm$userData3$user, _vm$userData3$user$da, _vm$userData4, _vm$userData4$user, _vm$userData4$user$da, _vm$userData5, _vm$userData5$user, _vm$userData5$user$da, _vm$userData6, _vm$userData6$user, _vm$userData6$user$da, _vm$userData7, _vm$userData7$user, _vm$userData7$user$da, _vm$userData8, _vm$userData8$user, _vm$userData8$user$da, _vm$userData8$user$da2, _vm$userData9, _vm$userData9$user, _vm$userData9$user$da, _vm$userData9$user$da2, _vm$userData10, _vm$userData11, _vm$userData11$user, _vm$userData11$user$d, _vm$userData12, _vm$userData12$user, _vm$userData12$user$d, _vm$userData13, _vm$userData13$user, _vm$userData13$user$d, _vm$userData14, _vm$userData14$user, _vm$userData14$user$d, _vm$userData15, _vm$userData15$user, _vm$userData15$user$d, _vm$userData16, _vm$userData16$user, _vm$userData16$user$d, _vm$userData17, _vm$userData17$user, _vm$userData17$user$d, _vm$userData17$user$d2, _vm$userData18, _vm$userData18$user, _vm$userData18$user$d, _vm$userData18$user$d2, _vm$userData19, _vm$userData20, _vm$userData20$user, _vm$userData20$user$d, _vm$userData21, _vm$userData21$user, _vm$userData21$user$d, _vm$userData22, _vm$userData22$user, _vm$userData22$user$d, _vm$userData23, _vm$userData23$user, _vm$userData23$user$d, _vm$userData24, _vm$userData24$user, _vm$userData24$user$d, _vm$userData25, _vm$userData25$user, _vm$userData25$user$d, _vm$userData26, _vm$userData26$user, _vm$userData26$user$d, _vm$userData26$user$d2, _vm$userData27, _vm$userData27$user, _vm$userData27$user$d, _vm$userData27$user$d2;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('b-card-actions', {
    attrs: {
      "title": "Filters",
      "action-collapse": ""
    }
  }, [_c('b-row', {
    staticClass: "second-layer-margin"
  }, [_c('b-col', {
    attrs: {
      "md": "3",
      "lg": "4",
      "xs": "12"
    }
  }, [_c('v-select', {
    staticClass: "mb-1 custom-font",
    attrs: {
      "options": _vm.filterEmpIdOption,
      "reduce": function reduce(item) {
        return item.id;
      },
      "label": "name",
      "placeholder": "Select Employee"
    },
    on: {
      "input": _vm.loadItems
    },
    scopedSlots: _vm._u([{
      key: "option",
      fn: function fn(data) {
        return [_c('UserSelect', {
          attrs: {
            "user": data
          }
        })];
      }
    }]),
    model: {
      value: _vm.filterEmpId,
      callback: function callback($$v) {
        _vm.filterEmpId = $$v;
      },
      expression: "filterEmpId"
    }
  })], 1), _c('b-col', {
    attrs: {
      "md": "3",
      "lg": "4",
      "xs": "12"
    }
  }, [_c('v-select', {
    staticClass: "mr-sm-1 mb-1 mb-sm-0 custom-font",
    attrs: {
      "options": _vm.leaveTypesOption,
      "reduce": function reduce(item) {
        return item.id;
      },
      "label": "name",
      "placeholder": "Select Leave Type"
    },
    on: {
      "input": _vm.loadItems
    },
    model: {
      value: _vm.filterLeaveType,
      callback: function callback($$v) {
        _vm.filterLeaveType = $$v;
      },
      expression: "filterLeaveType"
    }
  })], 1), _c('b-col', {
    attrs: {
      "md": "3",
      "lg": "4",
      "xs": "12"
    }
  }, [_c('v-select', {
    staticClass: "mr-sm-1 mb-1 mb-sm-0 custom-font",
    attrs: {
      "options": _vm.leaveStatusConstants,
      "reduce": function reduce(item) {
        return item.value;
      },
      "label": "name",
      "placeholder": "Select Leave Status"
    },
    on: {
      "input": _vm.loadSelectedLeaveStatus
    },
    model: {
      value: _vm.filterLeaveStatus,
      callback: function callback($$v) {
        _vm.filterLeaveStatus = $$v;
      },
      expression: "filterLeaveStatus"
    }
  })], 1), _c('b-col', {
    attrs: {
      "md": "3",
      "lg": "4",
      "xs": "12"
    }
  }, [_c('v-select', {
    staticClass: "mb-1 custom-font",
    attrs: {
      "options": _vm.filterEmpIdOption,
      "reduce": function reduce(item) {
        return item.id;
      },
      "label": "name",
      "placeholder": "Approver"
    },
    on: {
      "input": _vm.filterLeaveByDate
    },
    scopedSlots: _vm._u([{
      key: "option",
      fn: function fn(data) {
        return [_c('UserSelect', {
          attrs: {
            "user": data
          }
        })];
      }
    }]),
    model: {
      value: _vm.selectApproverId,
      callback: function callback($$v) {
        _vm.selectApproverId = $$v;
      },
      expression: "selectApproverId"
    }
  })], 1), _c('b-col', {
    attrs: {
      "md": "3",
      "lg": "4",
      "xs": "12"
    }
  }, [_c('v-select', {
    staticClass: "mb-1 custom-font",
    attrs: {
      "options": _vm.filterEmpIdOption,
      "reduce": function reduce(item) {
        return item.id;
      },
      "label": "name",
      "placeholder": "Proxy Approver"
    },
    on: {
      "input": _vm.filterLeaveByDate
    },
    scopedSlots: _vm._u([{
      key: "option",
      fn: function fn(data) {
        return [_c('UserSelect', {
          attrs: {
            "user": data
          }
        })];
      }
    }]),
    model: {
      value: _vm.selectProxyApproverId,
      callback: function callback($$v) {
        _vm.selectProxyApproverId = $$v;
      },
      expression: "selectProxyApproverId"
    }
  })], 1), _c('b-col', {
    attrs: {
      "md": "3",
      "lg": "4",
      "xs": "12"
    }
  }, [_c('flat-pickr', {
    staticClass: "form-control",
    attrs: {
      "placeholder": "Enter Date Range",
      "config": {
        mode: 'range',
        defaultDate: [this.filterStartDate, this.filterEndDate]
      }
    },
    on: {
      "input": _vm.filterLeaveByDate
    },
    model: {
      value: _vm.filterRangeDate,
      callback: function callback($$v) {
        _vm.filterRangeDate = $$v;
      },
      expression: "filterRangeDate"
    }
  })], 1)], 1)], 1), _c('b-card', [_c('div', [_c('vue-good-table', {
    attrs: {
      "style-class": "vgt-table striped table-custom-style",
      "line-numbers": false,
      "mode": "remote",
      "total-rows": _vm.totalRecords,
      "is-loading": _vm.isLoading,
      "rows": _vm.rows,
      "sort-options": {
        enabled: false,
        multipleColumns: true,
        initialSortBy: [{
          field: 'created_at',
          type: 'desc'
        }]
      },
      "columns": _vm.columns,
      "pagination-options": {
        enabled: true,
        perPage: _vm.pageLength
      }
    },
    on: {
      "update:isLoading": function updateIsLoading($event) {
        _vm.isLoading = $event;
      },
      "update:is-loading": function updateIsLoading($event) {
        _vm.isLoading = $event;
      },
      "on-page-change": _vm.onPageChange,
      "on-sort-change": _vm.onSortChange,
      "on-column-filter": _vm.onColumnFilter,
      "on-per-page-change": _vm.onPerPageChange
    },
    scopedSlots: _vm._u([{
      key: "table-row",
      fn: function fn(props) {
        var _props$row, _props$row2, _props$row2$user, _props$row3, _props$row3$leaveSeco, _props$row4, _props$row4$leaveSeco, _props$row5, _props$row5$leaveFirs, _props$row6, _props$row6$leaveFirs, _props$row7, _props$row8, _props$row9, _props$row10, _props$row11, _props$row11$leaveTyp, _props$row11$leaveTyp2, _props$row12, _props$row13, _props$row18, _props$row19, _props$row20, _props$row21, _props$row22, _props$row23, _props$row24, _props$row25, _props$row26, _props$row31, _props$row32, _props$row33, _props$row34;
        return [props.column.field === 'name' ? _c('span', [(_props$row = props.row) !== null && _props$row !== void 0 && _props$row.user_id ? [_c('UserCard', {
          attrs: {
            "user": props === null || props === void 0 ? void 0 : (_props$row2 = props.row) === null || _props$row2 === void 0 ? void 0 : (_props$row2$user = _props$row2.user) === null || _props$row2$user === void 0 ? void 0 : _props$row2$user.data
          }
        })] : _vm._e()], 2) : _vm._e(), props.column.field === 'approver' ? _c('span', [props !== null && props !== void 0 && (_props$row3 = props.row) !== null && _props$row3 !== void 0 && (_props$row3$leaveSeco = _props$row3.leaveSecondApprover) !== null && _props$row3$leaveSeco !== void 0 && _props$row3$leaveSeco.data ? [_c('UserCard', {
          attrs: {
            "user": props === null || props === void 0 ? void 0 : (_props$row4 = props.row) === null || _props$row4 === void 0 ? void 0 : (_props$row4$leaveSeco = _props$row4.leaveSecondApprover) === null || _props$row4$leaveSeco === void 0 ? void 0 : _props$row4$leaveSeco.data
          }
        }), _c('b-badge', {
          staticClass: "badge-glow",
          attrs: {
            "variant": "dark"
          }
        }, [_vm._v("Proxy")])] : props !== null && props !== void 0 && (_props$row5 = props.row) !== null && _props$row5 !== void 0 && (_props$row5$leaveFirs = _props$row5.leaveFirstApprover) !== null && _props$row5$leaveFirs !== void 0 && _props$row5$leaveFirs.data ? [_c('UserCard', {
          attrs: {
            "user": props === null || props === void 0 ? void 0 : (_props$row6 = props.row) === null || _props$row6 === void 0 ? void 0 : (_props$row6$leaveFirs = _props$row6.leaveFirstApprover) === null || _props$row6$leaveFirs === void 0 ? void 0 : _props$row6$leaveFirs.data
          }
        })] : [_c('b-badge', {
          attrs: {
            "variant": "light-warning"
          }
        }, [_vm._v("Pending")])]], 2) : _vm._e(), props.column.field === 'format_leave_days' ? _c('div', [(_props$row7 = props.row) !== null && _props$row7 !== void 0 && _props$row7.leave_days ? [_c('b-badge', {
          staticClass: "ml-1",
          attrs: {
            "variant": "light-primary"
          }
        }, [_vm._v(" " + _vm._s((_props$row8 = props.row) === null || _props$row8 === void 0 ? void 0 : _props$row8.leave_days) + " ")])] : _vm._e()], 2) : _vm._e(), props.column.field === 'format_is_reconciliation' ? _c('span', [(_props$row9 = props.row) !== null && _props$row9 !== void 0 && _props$row9.is_reconciliation ? [_c('b-badge', {
          staticClass: "ml-1",
          attrs: {
            "variant": "light-warning"
          }
        }, [_vm._v(" Yes ")])] : [_c('b-badge', {
          staticClass: "ml-1",
          attrs: {
            "variant": "light-success"
          }
        }, [_vm._v(" No ")])]], 2) : _vm._e(), props.column.field === 'format_leave_type' ? _c('div', [(_props$row10 = props.row) !== null && _props$row10 !== void 0 && _props$row10.leaveType ? [_c('b-badge', {
          staticClass: "ml-1",
          attrs: {
            "variant": "light-warning"
          }
        }, [_vm._v(" " + _vm._s((_props$row11 = props.row) === null || _props$row11 === void 0 ? void 0 : (_props$row11$leaveTyp = _props$row11.leaveType) === null || _props$row11$leaveTyp === void 0 ? void 0 : (_props$row11$leaveTyp2 = _props$row11$leaveTyp.data) === null || _props$row11$leaveTyp2 === void 0 ? void 0 : _props$row11$leaveTyp2.name) + " ")])] : _vm._e()], 2) : _vm._e(), props.column.field === 'format_status' ? _c('span', {
          staticClass: "text-center"
        }, [_c('b-badge', {
          staticClass: "d-flex justify-content-center",
          attrs: {
            "variant": _vm.statusVariant((_props$row12 = props.row) === null || _props$row12 === void 0 ? void 0 : _props$row12.status)
          }
        }, [_vm._v(" " + _vm._s((_props$row13 = props.row) === null || _props$row13 === void 0 ? void 0 : _props$row13.status) + " ")]), _c('div', {
          staticClass: "mt-1"
        }, [[_c('b-button', {
          directives: [{
            name: "ripple",
            rawName: "v-ripple.400",
            value: 'rgba(113, 102, 240, 0.15)',
            expression: "'rgba(113, 102, 240, 0.15)'",
            modifiers: {
              "400": true
            }
          }, {
            name: "b-tooltip",
            rawName: "v-b-tooltip.hover.top",
            value: 'Leave Balance',
            expression: "'Leave Balance'",
            modifiers: {
              "hover": true,
              "top": true
            }
          }],
          staticClass: "btn-icon mr-1",
          attrs: {
            "variant": "outline-primary"
          },
          on: {
            "click": function click($event) {
              var _props$row14, _props$row15, _props$row16, _props$row17, _props$row17$leaveTyp, _props$row17$leaveTyp2;
              return _vm.showLeaveBalance(props.row, (_props$row14 = props.row) === null || _props$row14 === void 0 ? void 0 : _props$row14.id, (_props$row15 = props.row) === null || _props$row15 === void 0 ? void 0 : _props$row15.user_id, (_props$row16 = props.row) === null || _props$row16 === void 0 ? void 0 : _props$row16.status, (_props$row17 = props.row) === null || _props$row17 === void 0 ? void 0 : (_props$row17$leaveTyp = _props$row17.leaveType) === null || _props$row17$leaveTyp === void 0 ? void 0 : (_props$row17$leaveTyp2 = _props$row17$leaveTyp.data) === null || _props$row17$leaveTyp2 === void 0 ? void 0 : _props$row17$leaveTyp2.name);
            }
          }
        }, [_c('feather-icon', {
          staticClass: "btn-icon",
          attrs: {
            "icon": "InfoIcon"
          }
        })], 1)], ((_props$row18 = props.row) === null || _props$row18 === void 0 ? void 0 : _props$row18.remarks) !== '' ? [_c('b-button', {
          directives: [{
            name: "ripple",
            rawName: "v-ripple.400",
            value: 'rgba(113, 102, 240, 0.15)',
            expression: "'rgba(113, 102, 240, 0.15)'",
            modifiers: {
              "400": true
            }
          }, {
            name: "b-tooltip",
            rawName: "v-b-tooltip.hover.top",
            value: 'Remarks: ' + ((_props$row19 = props.row) === null || _props$row19 === void 0 ? void 0 : _props$row19.remarks),
            expression: "'Remarks: '+props.row?.remarks",
            modifiers: {
              "hover": true,
              "top": true
            }
          }],
          staticClass: "btn-icon mr-1",
          attrs: {
            "variant": "outline-primary"
          }
        }, [_c('feather-icon', {
          staticClass: "btn-icon",
          attrs: {
            "icon": "InfoIcon"
          }
        })], 1)] : _vm._e(), (_props$row20 = props.row) !== null && _props$row20 !== void 0 && _props$row20.reconciliation_note ? [_c('b-button', {
          directives: [{
            name: "ripple",
            rawName: "v-ripple.400",
            value: 'rgba(113, 102, 240, 0.15)',
            expression: "'rgba(113, 102, 240, 0.15)'",
            modifiers: {
              "400": true
            }
          }, {
            name: "b-tooltip",
            rawName: "v-b-tooltip.hover.top",
            value: 'Reconciliation: ' + ((_props$row21 = props.row) === null || _props$row21 === void 0 ? void 0 : _props$row21.reconciliation_note),
            expression: "'Reconciliation: '+props.row?.reconciliation_note",
            modifiers: {
              "hover": true,
              "top": true
            }
          }],
          staticClass: "btn-icon",
          attrs: {
            "variant": "outline-primary"
          }
        }, [_c('feather-icon', {
          staticClass: "btn-icon",
          attrs: {
            "icon": "InfoIcon"
          }
        })], 1)] : _vm._e(), (_props$row22 = props.row) !== null && _props$row22 !== void 0 && _props$row22.reject_remarks ? [_c('b-button', {
          directives: [{
            name: "ripple",
            rawName: "v-ripple.400",
            value: 'rgba(113, 102, 240, 0.15)',
            expression: "'rgba(113, 102, 240, 0.15)'",
            modifiers: {
              "400": true
            }
          }, {
            name: "b-tooltip",
            rawName: "v-b-tooltip.hover.top",
            value: (_props$row23 = props.row) === null || _props$row23 === void 0 ? void 0 : _props$row23.reject_remarks,
            expression: "props.row?.reject_remarks",
            modifiers: {
              "hover": true,
              "top": true
            }
          }],
          staticClass: "btn-icon m-1",
          attrs: {
            "variant": "outline-warning"
          }
        }, [_c('feather-icon', {
          staticClass: "btn-icon",
          attrs: {
            "icon": "InfoIcon"
          }
        })], 1)] : _vm._e(), (props === null || props === void 0 ? void 0 : (_props$row24 = props.row) === null || _props$row24 === void 0 ? void 0 : _props$row24.leave_request_file) !== '' ? [_c('b-link', {
          directives: [{
            name: "b-tooltip",
            rawName: "v-b-tooltip.hover.top",
            value: 'Attached File',
            expression: "'Attached File'",
            modifiers: {
              "hover": true,
              "top": true
            }
          }],
          attrs: {
            "href": props === null || props === void 0 ? void 0 : (_props$row25 = props.row) === null || _props$row25 === void 0 ? void 0 : _props$row25.leave_request_file,
            "target": "_blank"
          }
        }, [_c('b-button', {
          directives: [{
            name: "ripple",
            rawName: "v-ripple.400",
            value: 'rgba(113, 102, 240, 0.15)',
            expression: "'rgba(113, 102, 240, 0.15)'",
            modifiers: {
              "400": true
            }
          }],
          staticClass: "btn-icon m-1",
          attrs: {
            "variant": "outline-primary"
          }
        }, [_c('feather-icon', {
          staticClass: "btn-icon",
          attrs: {
            "icon": "FileIcon"
          }
        })], 1)], 1)] : _vm._e()], 2)], 1) : _vm._e(), props.column.field === 'action' ? _c('span', [((_props$row26 = props.row) === null || _props$row26 === void 0 ? void 0 : _props$row26.status) === _vm.PENDING ? [_c('span', {
          on: {
            "click": function click($event) {
              var _props$row27, _props$row28, _props$row29, _props$row30, _props$row30$leaveTyp, _props$row30$leaveTyp2;
              return _vm.showLeaveBalanceApprove(props.row, (_props$row27 = props.row) === null || _props$row27 === void 0 ? void 0 : _props$row27.id, (_props$row28 = props.row) === null || _props$row28 === void 0 ? void 0 : _props$row28.user_id, (_props$row29 = props.row) === null || _props$row29 === void 0 ? void 0 : _props$row29.status, (_props$row30 = props.row) === null || _props$row30 === void 0 ? void 0 : (_props$row30$leaveTyp = _props$row30.leaveType) === null || _props$row30$leaveTyp === void 0 ? void 0 : (_props$row30$leaveTyp2 = _props$row30$leaveTyp.data) === null || _props$row30$leaveTyp2 === void 0 ? void 0 : _props$row30$leaveTyp2.name);
            }
          }
        }, [_c('feather-icon', {
          directives: [{
            name: "b-tooltip",
            rawName: "v-b-tooltip.hover",
            modifiers: {
              "hover": true
            }
          }],
          staticClass: "mr-50 custom-icon cursor-pointer",
          attrs: {
            "icon": "CheckIcon",
            "title": "Approve",
            "size": "16"
          }
        })], 1), _vm.$permissionAbility(_vm.LEAVE_APPROVE_REJECT, _vm.permissions) ? [((_props$row31 = props.row) === null || _props$row31 === void 0 ? void 0 : _props$row31.status) !== _vm.APPROVED ? [_c('span', {
          on: {
            "click": function click($event) {
              return _vm.rejectModalOpen(props.row);
            }
          }
        }, [_c('feather-icon', {
          directives: [{
            name: "b-tooltip",
            rawName: "v-b-tooltip.hover",
            modifiers: {
              "hover": true
            }
          }],
          staticClass: "mr-50 custom-icon cursor-pointer",
          attrs: {
            "icon": "XIcon",
            "title": "Reject",
            "size": "16"
          }
        })], 1)] : _vm._e()] : _vm._e()] : _vm._e(), _vm.$permissionAbility(_vm.LEAVE_APPROVE_REVERT, _vm.permissions) && (_props$row32 = props.row) !== null && _props$row32 !== void 0 && _props$row32.is_reconciliation && ((_props$row33 = props.row) === null || _props$row33 === void 0 ? void 0 : _props$row33.status) === _vm.APPROVED ? _c('span', {
          on: {
            "click": function click($event) {
              return _vm.onCanceled(props.row);
            }
          }
        }, [_c('feather-icon', {
          directives: [{
            name: "b-tooltip",
            rawName: "v-b-tooltip.hover",
            modifiers: {
              "hover": true
            }
          }],
          staticClass: "mr-50 custom-icon cursor-pointer",
          attrs: {
            "icon": "RefreshCwIcon",
            "title": "Leave Revert",
            "size": "16"
          }
        })], 1) : _vm._e(), _vm.$permissionAbility(_vm.LEAVE_REQUESTS_RECONCILIATION, _vm.permissions) && ((_props$row34 = props.row) === null || _props$row34 === void 0 ? void 0 : _props$row34.status) === _vm.APPROVED ? _c('span', {
          on: {
            "click": function click($event) {
              return _vm.onReconciliation(props.row);
            }
          }
        }, [_c('feather-icon', {
          directives: [{
            name: "b-tooltip",
            rawName: "v-b-tooltip.hover",
            modifiers: {
              "hover": true
            }
          }],
          staticClass: "mr-50 custom-icon cursor-pointer",
          attrs: {
            "icon": "RepeatIcon",
            "title": "Reconciliation",
            "size": "16"
          }
        })], 1) : _vm._e()], 2) : _c('span', [_vm._v(" " + _vm._s(props.formattedRow[props.column.field]) + " ")])];
      }
    }, {
      key: "pagination-bottom",
      fn: function fn(props) {
        return [_c('div', {
          staticClass: "d-flex justify-content-between flex-wrap"
        }, [_c('div', {
          staticClass: "d-flex align-items-center mb-0 mt-1"
        }, [_c('span', {
          staticClass: "text-nowrap"
        }, [_vm._v(" Showing 1 to ")]), _c('b-form-select', {
          staticClass: "mx-1",
          attrs: {
            "options": ['10', '25', '50', '100', '500']
          },
          on: {
            "input": function input(value) {
              return props.perPageChanged({
                currentPerPage: value
              });
            }
          },
          model: {
            value: _vm.pageLength,
            callback: function callback($$v) {
              _vm.pageLength = $$v;
            },
            expression: "pageLength"
          }
        }), _c('span', {
          staticClass: "text-nowrap"
        }, [_vm._v(" of " + _vm._s(props.total) + " entries ")])], 1), _c('div', [_c('b-pagination', {
          staticClass: "mt-1 mb-0",
          attrs: {
            "value": 1,
            "total-rows": props.total,
            "per-page": _vm.pageLength,
            "first-number": "",
            "last-number": "",
            "align": "right",
            "prev-class": "prev-item",
            "next-class": "next-item"
          },
          on: {
            "input": function input(value) {
              return props.pageChanged({
                currentPage: value
              });
            }
          },
          scopedSlots: _vm._u([{
            key: "prev-text",
            fn: function fn() {
              return [_c('feather-icon', {
                attrs: {
                  "icon": "ChevronLeftIcon",
                  "size": "18"
                }
              })];
            },
            proxy: true
          }, {
            key: "next-text",
            fn: function fn() {
              return [_c('feather-icon', {
                attrs: {
                  "icon": "ChevronRightIcon",
                  "size": "18"
                }
              })];
            },
            proxy: true
          }], null, true)
        })], 1)])];
      }
    }])
  })], 1), [_c('b-modal', {
    attrs: {
      "id": "modal-leave-balance-view",
      "centered": "",
      "title": 'Leave Balance',
      "hide-footer": "",
      "no-close-on-backdrop": "",
      "size": "lg"
    },
    on: {
      "hidden": _vm.hiddenLeaveBalanceModal
    }
  }, [_c('b-row', {
    staticClass: "justify-content-center"
  }, [_c('b-col', {
    attrs: {
      "cols": "12",
      "md": "8",
      "lg": "6"
    }
  }, [_c('div', {
    staticClass: "text-center"
  }, [(_vm$userData = _vm.userData) !== null && _vm$userData !== void 0 && _vm$userData.user ? [_c('b-avatar', {
    attrs: {
      "default": "''",
      "src": ((_vm$userData2 = _vm.userData) === null || _vm$userData2 === void 0 ? void 0 : (_vm$userData2$user = _vm$userData2.user) === null || _vm$userData2$user === void 0 ? void 0 : (_vm$userData2$user$da = _vm$userData2$user.data) === null || _vm$userData2$user$da === void 0 ? void 0 : _vm$userData2$user$da.avatar) === '' ? '/avatar.svg' : (_vm$userData3 = _vm.userData) === null || _vm$userData3 === void 0 ? void 0 : (_vm$userData3$user = _vm$userData3.user) === null || _vm$userData3$user === void 0 ? void 0 : (_vm$userData3$user$da = _vm$userData3$user.data) === null || _vm$userData3$user$da === void 0 ? void 0 : _vm$userData3$user$da.avatar
    }
  }), _c('h4', [_vm._v(_vm._s((_vm$userData4 = _vm.userData) === null || _vm$userData4 === void 0 ? void 0 : (_vm$userData4$user = _vm$userData4.user) === null || _vm$userData4$user === void 0 ? void 0 : (_vm$userData4$user$da = _vm$userData4$user.data) === null || _vm$userData4$user$da === void 0 ? void 0 : _vm$userData4$user$da.name) + " "), _c('b-badge', {
    attrs: {
      "variant": "light-secondary"
    }
  }, [_vm._v(_vm._s((_vm$userData5 = _vm.userData) === null || _vm$userData5 === void 0 ? void 0 : (_vm$userData5$user = _vm$userData5.user) === null || _vm$userData5$user === void 0 ? void 0 : (_vm$userData5$user$da = _vm$userData5$user.data) === null || _vm$userData5$user$da === void 0 ? void 0 : _vm$userData5$user$da.employee_number))])], 1), _c('a', {
    staticClass: "font-small-2 font-weight-bold",
    attrs: {
      "href": 'mailto:' + (((_vm$userData6 = _vm.userData) === null || _vm$userData6 === void 0 ? void 0 : (_vm$userData6$user = _vm$userData6.user) === null || _vm$userData6$user === void 0 ? void 0 : (_vm$userData6$user$da = _vm$userData6$user.data) === null || _vm$userData6$user$da === void 0 ? void 0 : _vm$userData6$user$da.email) || '')
    }
  }, [_vm._v(" " + _vm._s((_vm$userData7 = _vm.userData) === null || _vm$userData7 === void 0 ? void 0 : (_vm$userData7$user = _vm$userData7.user) === null || _vm$userData7$user === void 0 ? void 0 : (_vm$userData7$user$da = _vm$userData7$user.data) === null || _vm$userData7$user$da === void 0 ? void 0 : _vm$userData7$user$da.email) + " ")]), _vm._v(" "), _c('br'), _c('b-badge', {
    attrs: {
      "variant": "light-primary"
    }
  }, [_vm._v(" " + _vm._s((_vm$userData8 = _vm.userData) === null || _vm$userData8 === void 0 ? void 0 : (_vm$userData8$user = _vm$userData8.user) === null || _vm$userData8$user === void 0 ? void 0 : (_vm$userData8$user$da = _vm$userData8$user.data) === null || _vm$userData8$user$da === void 0 ? void 0 : (_vm$userData8$user$da2 = _vm$userData8$user$da.department) === null || _vm$userData8$user$da2 === void 0 ? void 0 : _vm$userData8$user$da2.name) + " ")]), _vm._v(" "), _c('br'), _c('b-badge', {
    attrs: {
      "variant": "light-primary"
    }
  }, [_vm._v(" " + _vm._s((_vm$userData9 = _vm.userData) === null || _vm$userData9 === void 0 ? void 0 : (_vm$userData9$user = _vm$userData9.user) === null || _vm$userData9$user === void 0 ? void 0 : (_vm$userData9$user$da = _vm$userData9$user.data) === null || _vm$userData9$user$da === void 0 ? void 0 : (_vm$userData9$user$da2 = _vm$userData9$user$da.designation) === null || _vm$userData9$user$da2 === void 0 ? void 0 : _vm$userData9$user$da2.name) + " ")])] : _vm._e()], 2)])], 1), _c('b-table', {
    staticClass: "mt-1",
    attrs: {
      "items": _vm.leaveBalanceRows,
      "fields": _vm.leaveBalanceColumns,
      "tbody-tr-class": _vm.leaveHighlight
    }
  }), _vm.selectedLeaveApprove && _vm.selectedLeaveStatus === _vm.PENDING ? _c('b-button', {
    directives: [{
      name: "ripple",
      rawName: "v-ripple.400",
      value: 'rgba(255, 255, 255, 0.15)',
      expression: "'rgba(255, 255, 255, 0.15)'",
      modifiers: {
        "400": true
      }
    }],
    staticClass: "float-right",
    attrs: {
      "type": "submit",
      "variant": "primary"
    },
    on: {
      "click": function click() {
        return _vm.onApprove();
      }
    }
  }, [_vm._v(" Approve ")]) : _vm._e()], 1)], [_c('b-modal', {
    attrs: {
      "id": "modal-reject-form",
      "centered": "",
      "title": "Leave Reject",
      "hide-footer": "",
      "no-close-on-backdrop": "",
      "size": "lg"
    },
    on: {
      "hidden": _vm.hiddenRejectModal
    }
  }, [_c('validation-observer', {
    ref: "rejectValidation"
  }, [_c('b-form', {
    on: {
      "submit": function submit($event) {
        $event.preventDefault();
        return _vm.rejectFromSubmit($event);
      }
    }
  }, [_c('b-row', {
    staticClass: "justify-content-center"
  }, [_c('b-col', {
    attrs: {
      "cols": "12",
      "md": "8",
      "lg": "6"
    }
  }, [_c('div', {
    staticClass: "text-center"
  }, [(_vm$userData10 = _vm.userData) !== null && _vm$userData10 !== void 0 && _vm$userData10.user_id ? [_c('b-avatar', {
    attrs: {
      "default": "''",
      "src": ((_vm$userData11 = _vm.userData) === null || _vm$userData11 === void 0 ? void 0 : (_vm$userData11$user = _vm$userData11.user) === null || _vm$userData11$user === void 0 ? void 0 : (_vm$userData11$user$d = _vm$userData11$user.data) === null || _vm$userData11$user$d === void 0 ? void 0 : _vm$userData11$user$d.avatar) === '' ? '/avatar.svg' : (_vm$userData12 = _vm.userData) === null || _vm$userData12 === void 0 ? void 0 : (_vm$userData12$user = _vm$userData12.user) === null || _vm$userData12$user === void 0 ? void 0 : (_vm$userData12$user$d = _vm$userData12$user.data) === null || _vm$userData12$user$d === void 0 ? void 0 : _vm$userData12$user$d.avatar
    }
  }), _c('h4', [_vm._v(_vm._s((_vm$userData13 = _vm.userData) === null || _vm$userData13 === void 0 ? void 0 : (_vm$userData13$user = _vm$userData13.user) === null || _vm$userData13$user === void 0 ? void 0 : (_vm$userData13$user$d = _vm$userData13$user.data) === null || _vm$userData13$user$d === void 0 ? void 0 : _vm$userData13$user$d.name) + " "), _c('b-badge', {
    attrs: {
      "variant": "light-secondary"
    }
  }, [_vm._v(_vm._s((_vm$userData14 = _vm.userData) === null || _vm$userData14 === void 0 ? void 0 : (_vm$userData14$user = _vm$userData14.user) === null || _vm$userData14$user === void 0 ? void 0 : (_vm$userData14$user$d = _vm$userData14$user.data) === null || _vm$userData14$user$d === void 0 ? void 0 : _vm$userData14$user$d.employee_number))])], 1), _c('a', {
    staticClass: "font-small-2 font-weight-bold",
    attrs: {
      "href": 'mailto:' + (((_vm$userData15 = _vm.userData) === null || _vm$userData15 === void 0 ? void 0 : (_vm$userData15$user = _vm$userData15.user) === null || _vm$userData15$user === void 0 ? void 0 : (_vm$userData15$user$d = _vm$userData15$user.data) === null || _vm$userData15$user$d === void 0 ? void 0 : _vm$userData15$user$d.email) || '')
    }
  }, [_vm._v(" " + _vm._s((_vm$userData16 = _vm.userData) === null || _vm$userData16 === void 0 ? void 0 : (_vm$userData16$user = _vm$userData16.user) === null || _vm$userData16$user === void 0 ? void 0 : (_vm$userData16$user$d = _vm$userData16$user.data) === null || _vm$userData16$user$d === void 0 ? void 0 : _vm$userData16$user$d.email) + " ")]), _vm._v(" "), _c('br'), _c('b-badge', {
    attrs: {
      "variant": "light-primary"
    }
  }, [_vm._v(" " + _vm._s((_vm$userData17 = _vm.userData) === null || _vm$userData17 === void 0 ? void 0 : (_vm$userData17$user = _vm$userData17.user) === null || _vm$userData17$user === void 0 ? void 0 : (_vm$userData17$user$d = _vm$userData17$user.data) === null || _vm$userData17$user$d === void 0 ? void 0 : (_vm$userData17$user$d2 = _vm$userData17$user$d.department) === null || _vm$userData17$user$d2 === void 0 ? void 0 : _vm$userData17$user$d2.name) + " ")]), _vm._v(" "), _c('br'), _c('b-badge', {
    attrs: {
      "variant": "light-primary"
    }
  }, [_vm._v(" " + _vm._s((_vm$userData18 = _vm.userData) === null || _vm$userData18 === void 0 ? void 0 : (_vm$userData18$user = _vm$userData18.user) === null || _vm$userData18$user === void 0 ? void 0 : (_vm$userData18$user$d = _vm$userData18$user.data) === null || _vm$userData18$user$d === void 0 ? void 0 : (_vm$userData18$user$d2 = _vm$userData18$user$d.designation) === null || _vm$userData18$user$d2 === void 0 ? void 0 : _vm$userData18$user$d2.name) + " ")])] : _vm._e()], 2)])], 1), _c('b-form-group', {
    staticClass: "required-label",
    attrs: {
      "label": "Remarks",
      "label-for": "rejection_remarks"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "Rejection Remarks",
      "vid": "rejection_remarks",
      "rules": "required"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref) {
        var errors = _ref.errors;
        return [_c('b-form-textarea', {
          attrs: {
            "id": "rejection_remarks",
            "state": errors.length > 0 ? false : null,
            "placeholder": "Remarks",
            "rows": "3"
          },
          model: {
            value: _vm.rejectionRemarks,
            callback: function callback($$v) {
              _vm.rejectionRemarks = $$v;
            },
            expression: "rejectionRemarks"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }])
  })], 1), [_vm.isLeaveRequestFormSubmitLoading ? [_c('b-button', {
    staticClass: "float-right",
    attrs: {
      "variant": "primary",
      "disabled": ""
    }
  }, [_c('b-spinner', {
    attrs: {
      "small": ""
    }
  }), _vm._v(" Loading... ")], 1)] : [_c('b-button', {
    directives: [{
      name: "ripple",
      rawName: "v-ripple.400",
      value: 'rgba(255, 255, 255, 0.15)',
      expression: "'rgba(255, 255, 255, 0.15)'",
      modifiers: {
        "400": true
      }
    }],
    staticClass: "float-right",
    attrs: {
      "type": "submit",
      "variant": "primary"
    }
  }, [_vm._v(" Submit ")])]]], 2)], 1)], 1)], _vm.$permissionAbility(_vm.LEAVE_REQUESTS_RECONCILIATION, _vm.permissions) ? [_c('b-modal', {
    attrs: {
      "id": "modal-leave-reconciliation-form",
      "centered": "",
      "title": "Leave Reconciliation",
      "hide-footer": "",
      "no-close-on-backdrop": ""
    },
    on: {
      "hidden": _vm.hiddenModal
    }
  }, [_c('b-row', {
    staticClass: "justify-content-center"
  }, [_c('b-col', {
    attrs: {
      "cols": "12",
      "md": "8",
      "lg": "6"
    }
  }, [_c('div', {
    staticClass: "text-center"
  }, [(_vm$userData19 = _vm.userData) !== null && _vm$userData19 !== void 0 && _vm$userData19.user ? [_c('b-avatar', {
    attrs: {
      "default": "''",
      "src": ((_vm$userData20 = _vm.userData) === null || _vm$userData20 === void 0 ? void 0 : (_vm$userData20$user = _vm$userData20.user) === null || _vm$userData20$user === void 0 ? void 0 : (_vm$userData20$user$d = _vm$userData20$user.data) === null || _vm$userData20$user$d === void 0 ? void 0 : _vm$userData20$user$d.avatar) === '' ? '/avatar.svg' : (_vm$userData21 = _vm.userData) === null || _vm$userData21 === void 0 ? void 0 : (_vm$userData21$user = _vm$userData21.user) === null || _vm$userData21$user === void 0 ? void 0 : (_vm$userData21$user$d = _vm$userData21$user.data) === null || _vm$userData21$user$d === void 0 ? void 0 : _vm$userData21$user$d.avatar
    }
  }), _c('h4', [_vm._v(_vm._s((_vm$userData22 = _vm.userData) === null || _vm$userData22 === void 0 ? void 0 : (_vm$userData22$user = _vm$userData22.user) === null || _vm$userData22$user === void 0 ? void 0 : (_vm$userData22$user$d = _vm$userData22$user.data) === null || _vm$userData22$user$d === void 0 ? void 0 : _vm$userData22$user$d.name) + " "), _c('b-badge', {
    attrs: {
      "variant": "light-secondary"
    }
  }, [_vm._v(_vm._s((_vm$userData23 = _vm.userData) === null || _vm$userData23 === void 0 ? void 0 : (_vm$userData23$user = _vm$userData23.user) === null || _vm$userData23$user === void 0 ? void 0 : (_vm$userData23$user$d = _vm$userData23$user.data) === null || _vm$userData23$user$d === void 0 ? void 0 : _vm$userData23$user$d.employee_number))])], 1), _c('a', {
    staticClass: "font-small-2 font-weight-bold",
    attrs: {
      "href": 'mailto:' + (((_vm$userData24 = _vm.userData) === null || _vm$userData24 === void 0 ? void 0 : (_vm$userData24$user = _vm$userData24.user) === null || _vm$userData24$user === void 0 ? void 0 : (_vm$userData24$user$d = _vm$userData24$user.data) === null || _vm$userData24$user$d === void 0 ? void 0 : _vm$userData24$user$d.email) || '')
    }
  }, [_vm._v(" " + _vm._s((_vm$userData25 = _vm.userData) === null || _vm$userData25 === void 0 ? void 0 : (_vm$userData25$user = _vm$userData25.user) === null || _vm$userData25$user === void 0 ? void 0 : (_vm$userData25$user$d = _vm$userData25$user.data) === null || _vm$userData25$user$d === void 0 ? void 0 : _vm$userData25$user$d.email) + " ")]), _vm._v(" "), _c('br'), _c('b-badge', {
    attrs: {
      "variant": "light-primary"
    }
  }, [_vm._v(" " + _vm._s((_vm$userData26 = _vm.userData) === null || _vm$userData26 === void 0 ? void 0 : (_vm$userData26$user = _vm$userData26.user) === null || _vm$userData26$user === void 0 ? void 0 : (_vm$userData26$user$d = _vm$userData26$user.data) === null || _vm$userData26$user$d === void 0 ? void 0 : (_vm$userData26$user$d2 = _vm$userData26$user$d.department) === null || _vm$userData26$user$d2 === void 0 ? void 0 : _vm$userData26$user$d2.name) + " ")]), _vm._v(" "), _c('br'), _c('b-badge', {
    attrs: {
      "variant": "light-primary"
    }
  }, [_vm._v(" " + _vm._s((_vm$userData27 = _vm.userData) === null || _vm$userData27 === void 0 ? void 0 : (_vm$userData27$user = _vm$userData27.user) === null || _vm$userData27$user === void 0 ? void 0 : (_vm$userData27$user$d = _vm$userData27$user.data) === null || _vm$userData27$user$d === void 0 ? void 0 : (_vm$userData27$user$d2 = _vm$userData27$user$d.designation) === null || _vm$userData27$user$d2 === void 0 ? void 0 : _vm$userData27$user$d2.name) + " ")])] : _vm._e()], 2)])], 1), _c('validation-observer', {
    ref: "leaveReconciliationValidation"
  }, [_c('b-form', {
    on: {
      "submit": function submit($event) {
        $event.preventDefault();
        return _vm.validationLeaveReconciliationForm($event);
      }
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Reconciliation Note",
      "label-for": "reconciliation-note"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "reconciliation_note",
      "vid": "reconciliation_note",
      "rules": "required"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref2) {
        var errors = _ref2.errors;
        return [_c('b-form-textarea', {
          attrs: {
            "id": "reconciliation-note",
            "state": errors.length > 0 ? false : null,
            "placeholder": "Reconciliation Note",
            "rows": "3"
          },
          model: {
            value: _vm.reconciliationNote,
            callback: function callback($$v) {
              _vm.reconciliationNote = $$v;
            },
            expression: "reconciliationNote"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }], null, false, 2306522118)
  })], 1), _vm.isLeaveReconciliationFormSubmitLoading ? [_c('b-button', {
    staticClass: "float-right",
    attrs: {
      "variant": "primary",
      "disabled": ""
    }
  }, [_c('b-spinner', {
    attrs: {
      "small": ""
    }
  }), _vm._v(" Loading... ")], 1)] : [_c('b-button', {
    directives: [{
      name: "ripple",
      rawName: "v-ripple.400",
      value: 'rgba(255, 255, 255, 0.15)',
      expression: "'rgba(255, 255, 255, 0.15)'",
      modifiers: {
        "400": true
      }
    }],
    staticClass: "float-right",
    attrs: {
      "type": "submit",
      "variant": "primary"
    }
  }, [_vm._v(" Submit ")])]], 2)], 1)], 1)] : _vm._e()], 2)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }