<template>
  <div>
    <b-card-actions
      title="Filters"
      action-collapse
    >
      <b-row class="second-layer-margin">

        <b-col
          md="3"
          lg="4"
          xs="12"
        >
          <v-select
            v-model="filterEmpId"
            :options="filterEmpIdOption"
            :reduce="(item) => item.id"
            label="name"
            placeholder="Select Employee"
            class="mb-1 custom-font"
            @input="loadItems"
          >
            <template #option="data">
              <UserSelect :user="data" />
            </template>
          </v-select>
        </b-col>
        
        <b-col
          md="3"
          lg="4"
          xs="12"
        >
          <v-select
            v-model="filterLeaveType"
            :options="leaveTypesOption"
            :reduce="(item) => item.id"
            label="name"
            placeholder="Select Leave Type"
            class="mr-sm-1 mb-1 mb-sm-0 custom-font"
            @input="loadItems"
          />
        </b-col>

        <b-col
          md="3"
          lg="4"
          xs="12"
        >
          <v-select
            v-model="filterLeaveStatus"
            :options="leaveStatusConstants"
            :reduce="(item) => item.value"
            label="name"
            placeholder="Select Leave Status"
            class="mr-sm-1 mb-1 mb-sm-0 custom-font"
            @input="loadSelectedLeaveStatus"
          />
        </b-col>


        <b-col
          md="3"
          lg="4"
          xs="12"
        >
          <v-select
            v-model="selectApproverId"
            :options="filterEmpIdOption"
            :reduce="(item) => item.id"
            label="name"
            placeholder="Approver"
            class="mb-1 custom-font"
            @input="filterLeaveByDate"
          >
            <template #option="data">
              <UserSelect :user="data" />
            </template>
          </v-select>
        </b-col>

        <b-col
          md="3"
          lg="4"
          xs="12"
        >
          <v-select
            v-model="selectProxyApproverId"
            :options="filterEmpIdOption"
            :reduce="(item) => item.id"
            label="name"
            placeholder="Proxy Approver"
            class="mb-1 custom-font"
            @input="filterLeaveByDate"
          >
            <template #option="data">
              <UserSelect :user="data" />
            </template>
          </v-select>
        </b-col>

        <b-col
          md="3"
          lg="4"
          xs="12"
        >

          <flat-pickr
            v-model="filterRangeDate"
            class="form-control"
            placeholder="Enter Date Range"
            :config="{ mode: 'range', defaultDate: [this.filterStartDate, this.filterEndDate]}"
            @input="filterLeaveByDate"
          />
        </b-col>

      </b-row>
    </b-card-actions>

    <b-card>

      <div>
        <!-- table -->
        <vue-good-table
          style-class="vgt-table striped table-custom-style"
          :line-numbers="false"
          mode="remote"
          :total-rows="totalRecords"
          :is-loading.sync="isLoading"
          :rows="rows"
          :sort-options="{
            enabled: false,
            multipleColumns: true,
            initialSortBy: [{ field: 'created_at', type: 'desc' }],
          }"
          :columns="columns"
          :pagination-options="{
            enabled: true,
            perPage: pageLength,
          }"
          @on-page-change="onPageChange"
          @on-sort-change="onSortChange"
          @on-column-filter="onColumnFilter"
          @on-per-page-change="onPerPageChange"
        >
          <template
            slot="table-row"
            slot-scope="props"
          >
            <!-- head -->
            <span v-if="props.column.field === 'name'">

              <template v-if="props.row?.user_id">
                <UserCard :user="props?.row?.user?.data" />
              </template>

            </span>

            <span v-if="props.column.field === 'approver'">
              <template v-if="props?.row?.leaveSecondApprover?.data">

                <UserCard :user="props?.row?.leaveSecondApprover?.data" />
                <b-badge
                  variant="dark"
                  class="badge-glow"
                >Proxy</b-badge>
              </template>
              <template v-else-if="props?.row?.leaveFirstApprover?.data">

                <UserCard :user="props?.row?.leaveFirstApprover?.data" />

              </template>
              <template v-else>
                <b-badge variant="light-warning">Pending</b-badge>
              </template>
            </span>

            <div v-if="props.column.field === 'format_leave_days'">
              <template v-if="props.row?.leave_days">
                <b-badge
                  class="ml-1"
                  variant="light-primary"
                >
                  {{ props.row?.leave_days }}
                </b-badge>
              </template>
            </div>

            <span v-if="props.column.field === 'format_is_reconciliation'">
              <template v-if="props.row?.is_reconciliation">
                <b-badge
                  class="ml-1"
                  variant="light-warning"
                >
                  Yes
                </b-badge>
              </template>
              <template v-else>
                <b-badge
                  class="ml-1"
                  variant="light-success"
                >
                  No
                </b-badge>
              </template>
            </span>

            <div v-if="props.column.field === 'format_leave_type'">
              <template v-if="props.row?.leaveType">
                <b-badge
                  class="ml-1"
                  variant="light-warning"
                >
                  {{ props.row?.leaveType?.data?.name }}
                </b-badge>
              </template>
            </div>

            <!-- Column: Status -->
            <span
              v-if="props.column.field === 'format_status'"
              class="text-center"
            >
              <b-badge
                class="d-flex justify-content-center"
                :variant="statusVariant(props.row?.status)"
              >
                {{ props.row?.status }}
              </b-badge>

              <div class="mt-1">

                <template>
                  <b-button
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    v-b-tooltip.hover.top="'Leave Balance'"
                    variant="outline-primary"
                    class="btn-icon mr-1"
                    @click="showLeaveBalance(props.row, props.row?.id, props.row?.user_id, props.row?.status, props.row?.leaveType?.data?.name)"
                  >
                    <feather-icon
                      class="btn-icon"
                      icon="InfoIcon"
                    />
                  </b-button>
                </template>

                <template v-if="props.row?.remarks !== ''">
                  <b-button
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    v-b-tooltip.hover.top="'Remarks: '+props.row?.remarks"
                    variant="outline-primary"
                    class="btn-icon mr-1"
                  >
                    <feather-icon
                      class="btn-icon"
                      icon="InfoIcon"
                    />
                  </b-button>
                </template>

                <template v-if="props.row?.reconciliation_note">
                  <b-button
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    v-b-tooltip.hover.top="'Reconciliation: '+props.row?.reconciliation_note"
                    variant="outline-primary"
                    class="btn-icon"
                  >
                    <feather-icon
                      class="btn-icon"
                      icon="InfoIcon"
                    />
                  </b-button>
                </template>

                <template v-if="props.row?.reject_remarks">
                  <b-button
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    v-b-tooltip.hover.top="props.row?.reject_remarks"
                    variant="outline-warning"
                    class="btn-icon m-1"
                  >
                    <feather-icon
                      class="btn-icon"
                      icon="InfoIcon"
                    />
                  </b-button>
                </template>

                <template v-if="props?.row?.leave_request_file !== ''">
                  <b-link
                    v-b-tooltip.hover.top="'Attached File'"
                    :href="props?.row?.leave_request_file"
                    target="_blank"
                  >
                    <b-button
                      v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                      variant="outline-primary"
                      class="btn-icon m-1"
                    >
                      <feather-icon
                        class="btn-icon"
                        icon="FileIcon"
                      />
                    </b-button>
                  </b-link>
                </template>

              </div>
            </span>

            <span v-if="props.column.field === 'action'">

              <template v-if="props.row?.status === PENDING">
                <span @click="showLeaveBalanceApprove(props.row, props.row?.id, props.row?.user_id, props.row?.status, props.row?.leaveType?.data?.name)">

                  <feather-icon
                    v-b-tooltip.hover
                    icon="CheckIcon"
                    class="mr-50 custom-icon cursor-pointer"
                    title="Approve"
                    size="16"
                  />
                </span>

                <template
                  v-if="$permissionAbility(LEAVE_APPROVE_REJECT, permissions)"
                >
                  <template v-if="props.row?.status !== APPROVED">
                    <span @click="rejectModalOpen(props.row)">
                      <feather-icon
                        v-b-tooltip.hover
                        icon="XIcon"
                        class="mr-50 custom-icon cursor-pointer"
                        title="Reject"
                        size="16"
                      />
                    </span>
                  </template>
                </template>

              </template>

              <span
                v-if="
                  $permissionAbility(
                    LEAVE_APPROVE_REVERT,
                    permissions
                  ) && props.row?.is_reconciliation && props.row?.status === APPROVED
                "
                @click="onCanceled(props.row)"
              >

                <feather-icon
                  v-b-tooltip.hover
                  icon="RefreshCwIcon"
                  class="mr-50 custom-icon cursor-pointer"
                  title="Leave Revert"
                  size="16"
                />
              </span>

              <span
                v-if="
                  $permissionAbility(
                    LEAVE_REQUESTS_RECONCILIATION,
                    permissions
                  ) && props.row?.status === APPROVED
                "
                @click="onReconciliation(props.row)"
              >

                <feather-icon
                  v-b-tooltip.hover
                  icon="RepeatIcon"
                  class="mr-50 custom-icon cursor-pointer"
                  title="Reconciliation"
                  size="16"
                />
              </span>
            </span>

            <!-- Column: Common -->
            <span v-else>
              {{ props.formattedRow[props.column.field] }}
            </span>
          </template>

          <!-- pagination -->
          <template
            slot="pagination-bottom"
            slot-scope="props"
          >
            <div class="d-flex justify-content-between flex-wrap">
              <div class="d-flex align-items-center mb-0 mt-1">
                <span class="text-nowrap"> Showing 1 to </span>
                <b-form-select
                  v-model="pageLength"
                  :options="['10', '25', '50', '100', '500']"
                  class="mx-1"
                  @input="
                    (value) => props.perPageChanged({ currentPerPage: value })
                  "
                />
                <span class="text-nowrap"> of {{ props.total }} entries </span>
              </div>
              <div>
                <b-pagination
                  :value="1"
                  :total-rows="props.total"
                  :per-page="pageLength"
                  first-number
                  last-number
                  align="right"
                  prev-class="prev-item"
                  next-class="next-item"
                  class="mt-1 mb-0"
                  @input="(value) => props.pageChanged({ currentPage: value })"
                >
                  <template #prev-text>
                    <feather-icon
                      icon="ChevronLeftIcon"
                      size="18"
                    />
                  </template>
                  <template #next-text>
                    <feather-icon
                      icon="ChevronRightIcon"
                      size="18"
                    />
                  </template>
                </b-pagination>
              </div>
            </div>
          </template>
        </vue-good-table>
      </div>

      <template>
        <b-modal
          id="modal-leave-balance-view"
          centered
          :title="'Leave Balance'"
          hide-footer
          no-close-on-backdrop
          size="lg"
          @hidden="hiddenLeaveBalanceModal"
        >

          <b-row class="justify-content-center">
            <b-col
              cols="12"
              md="8"
              lg="6"
            >
              <div class="text-center">
                <template v-if="userData?.user">
                  <b-avatar
                    default="''"
                    :src="userData?.user?.data?.avatar === '' ? '/avatar.svg' : userData?.user?.data?.avatar"
                  />
                  <h4>{{ userData?.user?.data?.name }} <b-badge variant="light-secondary">{{
                      userData?.user?.data?.employee_number
                  }}</b-badge></h4>
                  <a
                    :href="'mailto:' + (userData?.user?.data?.email || '')"
                    class="font-small-2 font-weight-bold"
                  >
                    {{ userData?.user?.data?.email }}
                  </a> <br>
                  <b-badge variant="light-primary">
                    {{ userData?.user?.data?.department?.name }}
                  </b-badge> <br>
                  <b-badge variant="light-primary">
                    {{ userData?.user?.data?.designation?.name }}
                  </b-badge>
                </template>
              </div>
            </b-col>
          </b-row>

          <b-table
            :items="leaveBalanceRows"
            :fields="leaveBalanceColumns"
            class="mt-1"
            :tbody-tr-class="leaveHighlight"
          />

          <b-button
            v-if="selectedLeaveApprove && selectedLeaveStatus === PENDING"
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            type="submit"
            class="float-right"
            variant="primary"
            @click="() => onApprove()"
          >
            Approve
          </b-button>
        </b-modal>
      </template>

      <template>
        <!-- Reject  -->
        <b-modal
          id="modal-reject-form"
          centered
          title="Leave Reject"
          hide-footer
          no-close-on-backdrop
          size="lg"
          @hidden="hiddenRejectModal"
        >
          <validation-observer ref="rejectValidation">
            <b-form @submit.prevent="rejectFromSubmit">

              <b-row class="justify-content-center">
                <b-col
                  cols="12"
                  md="8"
                  lg="6"
                >
                  <div class="text-center">

                    <template v-if="userData?.user_id">
                      <b-avatar
                        default="''"
                        :src="userData?.user?.data?.avatar === '' ? '/avatar.svg' : userData?.user?.data?.avatar"
                      />
                      <h4>{{ userData?.user?.data?.name }} <b-badge variant="light-secondary">{{
                          userData?.user?.data?.employee_number
                      }}</b-badge></h4>
                      <a
                        :href="'mailto:' + (userData?.user?.data?.email || '')"
                        class="font-small-2 font-weight-bold"
                      >
                        {{ userData?.user?.data?.email }}
                      </a> <br>
                      <b-badge variant="light-primary">
                        {{ userData?.user?.data?.department?.name }}
                      </b-badge> <br>
                      <b-badge variant="light-primary">
                        {{ userData?.user?.data?.designation?.name }}
                      </b-badge>
                    </template>
                  </div>
                </b-col>
              </b-row>

              <!-- description -->
              <b-form-group
                label="Remarks"
                label-for="rejection_remarks"
                class="required-label"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Rejection Remarks"
                  vid="rejection_remarks"
                  rules="required"
                >
                  <b-form-textarea
                    id="rejection_remarks"
                    v-model="rejectionRemarks"
                    :state="errors.length > 0 ? false : null"
                    placeholder="Remarks"
                    rows="3"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- submit button -->
              <template>

                <!-- loading button -->
                <template v-if="isLeaveRequestFormSubmitLoading">
                  <b-button
                    class="float-right"
                    variant="primary"
                    disabled
                  >
                    <b-spinner small />
                    Loading...
                  </b-button>
                </template>

                <!-- submit button -->
                <template v-else>
                  <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    type="submit"
                    class="float-right"
                    variant="primary"
                  >
                    Submit
                  </b-button>
                </template>

              </template>

            </b-form>
          </validation-observer>
        </b-modal>
      </template>

      <!-- LEAVE_REQUESTS_RECONCILIATION modal -->
      <template
        v-if="$permissionAbility(LEAVE_REQUESTS_RECONCILIATION, permissions)"
      >
        <b-modal
          id="modal-leave-reconciliation-form"
          centered
          title="Leave Reconciliation"
          hide-footer
          no-close-on-backdrop
          @hidden="hiddenModal"
        >
          <b-row class="justify-content-center">
            <b-col
              cols="12"
              md="8"
              lg="6"
            >
              <div class="text-center">
                <template v-if="userData?.user">
                  <b-avatar
                    default="''"
                    :src="userData?.user?.data?.avatar === '' ? '/avatar.svg' : userData?.user?.data?.avatar"
                  />
                  <h4>{{ userData?.user?.data?.name }} <b-badge variant="light-secondary">{{
                      userData?.user?.data?.employee_number
                  }}</b-badge></h4>
                  <a
                    :href="'mailto:' + (userData?.user?.data?.email || '')"
                    class="font-small-2 font-weight-bold"
                  >
                    {{ userData?.user?.data?.email }}
                  </a> <br>
                  <b-badge variant="light-primary">
                    {{ userData?.user?.data?.department?.name }}
                  </b-badge> <br>
                  <b-badge variant="light-primary">
                    {{ userData?.user?.data?.designation?.name }}
                  </b-badge>
                </template>
              </div>
            </b-col>
          </b-row>
          <validation-observer ref="leaveReconciliationValidation">
            <!-- validationForm -->
            <b-form @submit.prevent="validationLeaveReconciliationForm">
              <!-- remarks -->
              <b-form-group
                label="Reconciliation Note"
                label-for="reconciliation-note"
              >
                <validation-provider
                  #default="{ errors }"
                  name="reconciliation_note"
                  vid="reconciliation_note"
                  rules="required"
                >
                  <b-form-textarea
                    id="reconciliation-note"
                    v-model="reconciliationNote"
                    :state="errors.length > 0 ? false : null"
                    placeholder="Reconciliation Note"
                    rows="3"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- loading button -->
              <template v-if="isLeaveReconciliationFormSubmitLoading">
                <b-button
                  class="float-right"
                  variant="primary"
                  disabled
                >
                  <b-spinner small />
                  Loading...
                </b-button>
              </template>

              <!-- submit button -->
              <template v-else>
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  type="submit"
                  class="float-right"
                  variant="primary"
                >
                  Submit
                </b-button>
              </template>
            </b-form>
          </validation-observer>
        </b-modal>
      </template>
    </b-card>
  </div>
</template>

<script>
import BCardActions from '@core/components/b-card-actions/BCardActions.vue'
import flatPickr from 'vue-flatpickr-component'
import {
  BCard,
  BAvatar,
  BBadge,
  BPagination,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BDropdown,
  BDropdownItem,
  BButton,
  BForm,
  BModal,
  VBTooltip,
  BFormDatepicker,
  BFormTextarea,
  BSpinner,
  BFormFile,
  BLink, BRow, BCol, BTable,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import Ripple from 'vue-ripple-directive'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, max, size } from '@validations'
import {
  leaveStatusConstants,
  PENDING,
  APPROVED,
  CANCELED,
  REJECTED,
} from '@/helpers/constant/leaveRequestStatusConstant'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { mapGetters } from 'vuex'
import {
  LEAVE_REQUESTS_CREATE,
  LEAVE_REQUESTS_EDIT,
  LEAVE_REQUESTS_DELETE,
  LEAVE_APPROVE_ACCEPT,
  LEAVE_APPROVE_SELF_ACCEPT,
  LEAVE_APPROVE_DIVISION_HEAD_ACCEPT,
  LEAVE_APPROVE_DEPT_HEAD_ACCEPT,
  LEAVE_APPROVE_REJECT,
  LEAVE_APPROVE_CANCELED,
  LEAVE_APPROVE_REVERT,
  LEAVE_REQUESTS_RECONCILIATION,
} from '@/helpers/permissionsConstant'

import UserCard from '@/layouts/components/UserCard.vue'
import UserSelect from '@/layouts/components/UserSelect.vue'
import FormatCurrency from '@/layouts/components/FormatCurrency.vue'
import { formatDateRange } from '@/helpers/helpers'

export default {
  name: 'LeaveApproveView',
  components: {
    BTable,
    FormatCurrency,
    UserSelect,
    BForm,
    BRow,
    BCol,
    BButton,
    BCard,
    UserCard,
    BCardActions,
    VueGoodTable,
    BAvatar,
    BBadge,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BDropdown,
    BDropdownItem,
    ValidationProvider,
    ValidationObserver,
    BModal,
    BFormDatepicker,
    BFormTextarea,
    BSpinner,
    BFormFile,
    BLink,
    flatPickr,
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  data() {
    return {
      LEAVE_REQUESTS_CREATE,
      LEAVE_REQUESTS_EDIT,
      LEAVE_REQUESTS_DELETE,
      LEAVE_APPROVE_ACCEPT,
      LEAVE_APPROVE_SELF_ACCEPT,
      LEAVE_APPROVE_DIVISION_HEAD_ACCEPT,
      LEAVE_APPROVE_DEPT_HEAD_ACCEPT,
      LEAVE_APPROVE_REJECT,
      LEAVE_APPROVE_CANCELED,
      LEAVE_APPROVE_REVERT,
      LEAVE_REQUESTS_RECONCILIATION,
      PENDING,
      APPROVED,
      CANCELED,
      REJECTED,
      filterEmpId: '',
      filterLeaveType: '',
      filterEmpIdOption: [],
      filterLeaveTypeOption: [],
      filterApproverEmpIdOption: [],
      filterProxyApproverEmpIdOption: [],
      filterRangeDate: null,
      selectApproverId: '',
      selectProxyApproverId: '',
      modelType: '',
      leaveRequestId: '',
      filterStartedDate: '',
      filterStartDate: '',
      filterEndDate: '',
      filterLeaveStatus: '',
      leaveStatusConstants,
      selectedLeave: '',
      selectedLeaveApprove: '',
      selectedLeaveStatus: '',
      selectedLeaveType: '',
      userData: [],
      start_date: '',
      end_date: '',
      remarks: '',
      rejectionRemarks: '',
      file: null,
      selectLeaveTypeId: '',
      filterLeaveTypeId: '',
      leaveTypesOption: [],
      reconciliationNote: '',
      rejectLeaveId: '',
      isLeaveReconciliationFormSubmitLoading: false,
      pageLength: 10,
      columns: [
        {
          label: 'Employee',
          field: 'name',
          sortable: false,
        },
        {
          label: 'Approver',
          field: 'approver',
          sortable: false,
        },
        {
          label: 'Leave Type',
          field: 'format_leave_type',
          sortable: false,
        },
        {
          label: 'Start date',
          field: 'start_date',
          sortable: false,
          formatFn: this.formatDate,
        },
        {
          label: 'End date',
          field: 'end_date',
          sortable: false,
          formatFn: this.formatDate,
        },
        {
          label: 'No. of Days',
          field: 'format_leave_days',
          sortable: false,
        },
        {
          label: 'Status',
          field: 'format_status',
          sortable: false,
        },
        {
          label: 'Applied On',
          field: 'created_at',
          formatFn: this.formatDate,
        },
        {
          label: 'Reconciled',
          field: 'format_is_reconciliation',
          sortable: false,
          formatFn: this.formatFnTableIsReconciliation,
        },
        {
          label: 'Action',
          field: 'action',
          sortable: false,
        },
      ],
      leaveBalanceColumns: [
        { key: 'leave', label: 'Leave' },
        { key: 'allowance', label: 'Allowance' },
        { key: 'availed', label: 'Availed' },
        { key: 'remaining', label: 'Remaining', tdClass: 'nameOfTheClass' },
      ],
      rows: [],
      leaveBalanceRows: [],
      // searchTerm: "",
      // delayTimer: null,
      isLoading: false,
      totalRecords: 0,
      serverParams: {
        columnFilters: {},
        sort: [{ field: 'id', type: 'desc' }],
        page: 1,
        perPage: 10,
      },
      isLeaveRequestFormSubmitLoading: false,
    }
  },

  computed: {
    ...mapGetters({
      permissions: 'userModule/getPermissions',
      authUser: 'userModule/getUser',
    }),
  },

  async created() {
    try {
      const [leaveTypes, employees] = await Promise.all([this.getLeaveTypes(), this.getActiveEmployees()])

      // leave types
      this.leaveTypesOption = (leaveTypes?.data?.data || []).map(item => ({
        name: item?.name,
        id: item.id,
      }))

      // Main approve
      this.filterEmpIdOption = (employees?.data?.data || []).map(item => ({
        avatar: item?.avatar,
        name: item?.name,
        email: item?.email,
        mobile: item?.mobile,
        employee_number: item?.employee_number,
        id: item?.id,
        departmentId: item?.department_id,
      }))
    } catch (error) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Error',
          icon: 'BellIcon',
          variant: 'danger',
          text: error?.response?.data?.message,
        },
      })
    }
  },

  methods: {

    hiddenRejectModal() {
      this.$bvModal.hide('modal-reject-form')
      this.rejectionRemarks = ''
      this.rejectLeaveId = ''
      this.userData = ''
    },

    rejectModalOpen(data) {
      this.$bvModal.show('modal-reject-form')
      this.userData = data
      this.rejectLeaveId = data?.id
    },

    async rejectFromSubmit() {
      this.$refs.rejectValidation.validate().then(async success => {
        if (success) {
          try {
            this.$swal({
              title: 'Warning!',
              text: `Are You Sure, You Want to Reject Leave of ${this.userData?.user?.data?.name}?`,
              icon: 'warning',
              customClass: {
                confirmButton: 'btn btn-primary',
                cancelButton: 'btn btn-outline-danger ml-1',
              },
              buttonsStyling: false,
              showCancelButton: true,
              confirmButtonText: 'Ok',
              showLoaderOnConfirm: true,
            }).then(async result => {
              if (result.isConfirmed) {
                this.isLeaveRequestFormSubmitLoading = true

                await this.$api.post('api/leave-approve/reject', {
                  leave_request_id: this.rejectLeaveId,
                  rejection_remarks: this.rejectionRemarks,
                })

                this.hiddenRejectModal()
                this.loadItems()

                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: 'Success',
                    icon: 'BellIcon',
                    variant: 'success',
                    text: 'Successfully Rejected',
                  },
                })
              }
            })
          } catch (error) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Error',
                icon: 'BellIcon',
                variant: 'danger',
                text: error?.response?.data?.message,
              },
            })
          } finally {
            this.isLeaveRequestFormSubmitLoading = false
          }
        }
      })
    },

    leaveHighlight(item) {
      return item.leave === this.selectedLeaveType ? 'bg-light-danger' : ''
    },

    showLeaveBalance(row, id, userId, status, leaveType) {
      this.selectedLeave = ''
      this.selectedLeaveStatus = ''
      this.loadLeaveBalance(userId)
      this.userData = row
      this.showLeaveBalanceModal(id, status, leaveType)
    },
    showLeaveBalanceApprove(row, id, userId, status, leaveType) {
      this.selectedLeaveApprove = true
      this.selectedLeave = ''
      this.selectedLeaveStatus = ''
      this.userData = row
      this.loadLeaveBalance(userId)

      this.showLeaveBalanceModal(id, status, leaveType)
    },

    async loadLeaveBalance(userID) {
      try {
        const [leaveTypes] = await Promise.all([
          this.getLeaveBalance(userID),
        ])

        const data = leaveTypes?.data?.data

        this.leaveBalanceRows = data.map(data => {
          const owned = data.leave_activity?.leave_owned ?? 0

          return {
            leave: data.name,
            allowance: data.leave_allowance,
            availed: owned,
            remaining: data.leave_allowance - owned,
          }
        })
      } catch (error) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Warning',
            icon: 'BellIcon',
            variant: 'warning',
            text: error?.response?.data?.message,
          },
        })
      }
    },

    showLeaveBalanceModal(id, status, leaveType) {
      this.selectedLeave = id
      this.selectedLeaveStatus = status
      this.selectedLeaveType = leaveType
      this.$bvModal.show('modal-leave-balance-view')
    },

    hiddenLeaveBalanceModal() {
      this.selectedLeaveApprove = false
      this.selectedLeave = ''
      this.selectedLeaveStatus = ''
      this.selectedLeaveType = ''
      this.userData = ''
      this.$bvModal.hide('modal-leave-balance-view')
    },

    async getActiveEmployees() {
      return await this.$api.get('api/users/active-all')
    },

    loadSelectedLeaveStatus() {
      try {
        this.loadItems()
      } catch (error) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Warning',
            icon: 'BellIcon',
            variant: 'warning',
            text: error?.response?.data?.message,
          },
        })
      }
    },

    filterLeaveByDate() {
      this.loadItems()
    },

    statusVariant(value) {
      if (value === this.APPROVED) {
        return 'light-success'
      } if (value === this.REJECTED) {
        return 'light-danger'
      } if (value === this.PENDING) {
        return 'light-warning'
      }
      return 'light-warning'
    },
    formatFnTableIsReconciliation(value) {
      if (value) {
        return 'Yes'
      }
    },
    showModal() {
      this.$bvModal.show('modal-leave-request-form')
    },
    showReconciliationModal() {
      this.$bvModal.show('modal-leave-reconciliation-form')
    },
    hiddenModal() {
      this.userData = ''
      this.$bvModal.hide('modal-leave-request-form')
      this.resetModal()
    },
    hiddenReconciliationModal() {
      this.userData = ''
      this.$bvModal.hide('modal-leave-reconciliation-form')
      this.resetModal()
    },
    resetModal() {
      this.modelType = ''
      this.start_date = ''
      this.end_date = ''
      this.remarks = ''
      this.file = null
      this.selectLeaveTypeId = ''
      this.leaveRequestId = ''
      this.reconciliationNote = ''
      this.userData = ''
    },
    async onShow(value) {
      this.modelType = 'editModel'

      this.leaveRequestId = value?.id
      this.start_date = value?.start_date
      this.end_date = value?.end_date
      this.remarks = value?.remarks
      this.selectLeaveTypeId = value?.leave_type_id

      this.showModal()
    },
    onReconciliation(value) {
      this.userData = value
      this.leaveRequestId = value?.id
      this.reconciliationNote = value?.reconciliation_note
      this.showReconciliationModal()
    },
    async onApprove() {
      try {
        await this.$api.post('api/leave-approve/approve', {
          leave_request_id: this.selectedLeave,
        })

        this.loadItems()
        this.hiddenLeaveBalanceModal()

        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Success',
            icon: 'BellIcon',
            variant: 'success',
            text: 'Successfully Approved',
          },
        })
      } catch (error) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Warning',
            icon: 'BellIcon',
            variant: 'warning',
            text: error?.response?.data?.message,
          },
        })
      }
    },
    async onReject(row) {
      const { id } = row
      this.$swal({
        title: 'Warning!',
        text: `Are You Sure You Want To Reject The Leave Application of ${row?.user?.data?.name}?`,
        icon: 'warning',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
        showCancelButton: true,
        confirmButtonText: 'Ok',
        showLoaderOnConfirm: true,
      }).then(async result => {
        if (result.isConfirmed) {
          try {
            await this.$api.post('api/leave-approve/reject', {
              leave_request_id: id,
            })

            this.loadItems()

            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Success',
                icon: 'BellIcon',
                variant: 'success',
                text: 'Successfully Rejected',
              },
            })
          } catch (error) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Error',
                icon: 'BellIcon',
                variant: 'danger',
                text: error?.response?.data?.message,
              },
            })
          }
        }
      })
    },
    async onCanceled(row) {
      this.$swal({
        title: 'Warning!',
        text: `Are You Sure You Want To Revert Leave of ${row?.user?.data?.name}?`,
        icon: 'warning',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
        showCancelButton: true,
        confirmButtonText: 'Ok',
        showLoaderOnConfirm: true,
      }).then(async result => {
        if (result.isConfirmed) {
          try {
            await this.$api.post('api/leave-approve/cancel', {
              leave_request_id: row.id,
            })

            this.loadItems()

            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Success',
                icon: 'BellIcon',
                variant: 'success',
                text: 'Successfully Reverted',
              },
            })
          } catch (error) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Error',
                icon: 'BellIcon',
                variant: 'danger',
                text: error?.response?.data?.message,
              },
            })
          }
        }
      })
    },

    updateParams(newProps) {
      this.serverParams = { ...this.serverParams, ...newProps }
    },

    onPageChange(params) {
      this.updateParams({ page: params.currentPage })
      this.loadItems()
    },

    onPerPageChange(params) {
      this.updateParams({ perPage: params.currentPerPage })
      this.loadItems()
    },

    onSortChange(params) {
      this.updateParams({
        sort: params,
      })
      this.loadItems()
    },

    onColumnFilter(params) {
      this.updateParams(params)
      this.loadItems()
    },

    async getLeaveRequests(params) {
      return await this.$api.get(
        'api/leave-approve?include=leaveType,user,leaveFirstApprover,leaveSecondApprover',
        {
          params: {
            show: params.show,
            page: params.page,
            sort: params.sort,
            q: params.q,
            filterLeaveStatus: params.filterLeaveStatus,
            filterStartedDate: params.filterStartedDate,
            filterEndDate: params.filterEndDate,
            filterLeaveType: params.filterLeaveType,
            filterEmpId: params.filterEmpId,
            filterApprover: params.filterApprover,
            filterProxyApprover: params.filterProxyApprover,
          },
        },
      )
    },
    async getLeaveBalance(userId = '') {
      return await this.$api.get(`api/leave/balance?user_id=${userId}`)
    },
    async getLeaveTypes() {
      return await this.$api.get(`api/leave-types/current-year-all`)
    },

    async loadItems() {
      try {
        const { startDate, endDate } = formatDateRange(this.filterRangeDate)

        const [leaveRequests] = await Promise.all([
          this.getLeaveRequests({
            show: this.serverParams.perPage,
            page: this.serverParams.page,
            sort: this.serverParams.sort,
            q: this.filterStartedDate,
            filterStartedDate: startDate,
            filterEndDate: endDate,
            filterLeaveType: this.filterLeaveType,
            filterLeaveStatus: this.filterLeaveStatus,
            filterEmpId: this.filterEmpId,
            filterApprover: this.selectApproverId,
            filterProxyApprover: this.selectProxyApproverId,
          }),
        ])

        const data = leaveRequests?.data?.data

        const meta = leaveRequests?.data?.meta

        this.totalRecords = meta?.pagination?.total

        // data = data.filter((row) => {
        //   // Return true for elements that meet the criteria
        //   return this.validateLeaveApprovePermission(row);
        // })

        this.rows = data
      } catch (error) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Warning',
            icon: 'BellIcon',
            variant: 'warning',
            text: error?.response?.data?.message,
          },
        })
      }
    },
    async validationForm() {
      this.$refs.leaveRequestValidation.validate().then(async success => {
        if (success) {
          try {
            if (this.modelType == 'editModel') {
              this.isLeaveRequestFormSubmitLoading = true

              const formData = new FormData()

              formData.append('_method', 'PUT')

              if (this.file) {
                formData.append('leave_request_file', this.file)
              }

              if (this.start_date) {
                formData.append('start_date', this.start_date)
              }

              if (this.end_date) {
                formData.append('end_date', this.end_date)
              }

              if (this.remarks) {
                formData.append('remarks', this.remarks)
              }

              if (this.selectLeaveTypeId) {
                formData.append('leave_type_id', this.selectLeaveTypeId)
              }

              await this.$api.post(
                `/api/leave-requests/${this.leaveRequestId}`,
                formData,
                {
                  headers: {
                    'Content-Type': 'multipart/form-data',
                  },
                },
              )
              this.isLeaveRequestFormSubmitLoading = false
              this.loadItems()
              this.hiddenModal()
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Success',
                  icon: 'BellIcon',
                  variant: 'success',
                  text: 'Successfully Updated',
                },
              })
            } else {
              this.isLeaveRequestFormSubmitLoading = true

              const formData = new FormData()

              if (this.file) {
                formData.append('leave_request_file', this.file)
              }

              if (this.start_date) {
                formData.append('start_date', this.start_date)
              }

              if (this.end_date) {
                formData.append('end_date', this.end_date)
              }

              if (this.remarks) {
                formData.append('remarks', this.remarks)
              }

              if (this.selectLeaveTypeId) {
                formData.append('leave_type_id', this.selectLeaveTypeId)
              }

              await this.$api.post('/api/leave-requests', formData, {
                headers: {
                  'Content-Type': 'multipart/form-data',
                },
              })
              this.isLeaveRequestFormSubmitLoading = false
              this.hiddenModal()

              this.loadItems()

              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Success',
                  icon: 'BellIcon',
                  variant: 'success',
                  text: 'Successfully Created',
                },
              })
            }
          } catch (error) {
            this.isLeaveRequestFormSubmitLoading = false
            if (error?.response?.data?.message) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Error',
                  icon: 'BellIcon',
                  variant: 'danger',
                  text: error?.response?.data?.message,
                },
              })
            }

            if (error?.response?.data?.errors) {
              this.$refs.leaveRequestValidation.setErrors(
                  error?.response?.data?.errors,
              )
            }
          }
        }
      })
    },
    async validationLeaveReconciliationForm() {
      this.$refs.leaveReconciliationValidation
        .validate()
        .then(async success => {
          if (success) {
            try {
              this.isLeaveReconciliationFormSubmitLoading = true

              await this.$api.post(
                `api/leave-requests/${this.leaveRequestId}/reconciliation`,
                {
                  reconciliation_note: this.reconciliationNote,
                },
              )
              this.isLeaveReconciliationFormSubmitLoading = false
              this.hiddenReconciliationModal()

              this.loadItems()

              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Success',
                  icon: 'BellIcon',
                  variant: 'success',
                  text: 'Successfully Created',
                },
              })
            } catch (error) {
              this.isLeaveReconciliationFormSubmitLoading = false
              if (error?.response?.data?.message) {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: 'Error',
                    icon: 'BellIcon',
                    variant: 'danger',
                    text: error?.response?.data?.message,
                  },
                })
              }

              if (error?.response?.data?.errors) {
                this.$refs.leaveRequestValidation.setErrors(
                      error?.response?.data?.errors,
                )
              }
            }
          }
        })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
.table-custom-style {
  font-size: 14px !important;
  white-space: nowrap !important;
  min-height: 140px !important;
  tr,
  th,
  td {
    vertical-align: middle !important;
  }
}

@import "@core/scss/vue/libs/vue-good-table.scss";
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
